


























































import { defineComponent } from '@nuxtjs/composition-api'
import algoliasearch from 'algoliasearch'
import debounce from 'lodash.debounce'

import svgCross from '@/assets/icons/cross.svg?inline'
import svgSearch from '@/assets/icons/search.svg?inline'
import { Breakpoints } from '~/types/constants'
import useResize from '~/utils/compositions/useResize'

export default defineComponent({
  components: { svgSearch, svgCross },
  data: () => ({
    inpPlaceholderKey: '',
    items: [] as any[],
    text: '',
    isFocus: false,
  }),
  computed: {
    showPlaceholder() {
      if (this.text || this.isFocus) return false
      return true
    },
    normItems(): any[] {
      return this.items.map((item) => {
        const localeTitle = this.$getLocaleValue(item._highlightResult.title) as any
        return {
          highlightedTitle: localeTitle?.value,
          slug: item.slug,
        }
      })
    },
    placeholderTexts(): any {
      return {
        desktop: 'searchPlaceholder',
        mob: 'searchPlaceholderMob',
      }
    },
    searchClient() {
      // @ts-ignore
      return algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
    },
    inpPlaceholder(): string {
      return this.$i18n.t(this.inpPlaceholderKey) as string
    },
  },
  mounted() {
    const checkSize = (wWidth: number) => {
      if (wWidth < Breakpoints.md) {
        this.inpPlaceholderKey = this.placeholderTexts.mob
      } else {
        this.inpPlaceholderKey = this.placeholderTexts.desktop
      }
    }
    useResize(checkSize)
    setTimeout(() => this.initPlaceholder(), 100)
  },
  methods: {
    initPlaceholder() {
      const link = (this.$refs.label as Element)?.querySelector('a')
      link?.addEventListener('click', (e) => {
        e.preventDefault()
        this.text = link?.innerHTML as string
        this.search(this)
      })
    },
    resetItems() {
      this.text = ''
      this.items = []
    },
    onFocus() {
      this.isFocus = true
    },
    onClickLabelFocus() {
      this.isFocus = true
      this.$nextTick(() => (this.$refs.input as any).focus())
    },
    onBlur() {
      this.isFocus = false
    },
    resetText() {
      this.resetItems()
      ;(this.$refs.input as any).focus()
    },
    onSearch() {
      if (!this.text) return
      this.$router.push(
        this.localePath({
          name: 'search',
          params: { phrase: this.text },
        }),
      )
      this.resetItems()
    },

    async onInput() {
      await this.search(this)
    },

    search: debounce(async (vm) => {
      const index = await vm.searchClient.initIndex('products')
      if (vm.text.length < 2) {
        // eslint-disable-next-line no-param-reassign
        vm.items = [] as any[]
      } else {
        const result = await index.search(vm.text, {
          attributesToHighlight: ['title'],
          hitsPerPage: 8,
        })
        // eslint-disable-next-line no-param-reassign
        vm.items = result.hits as any[]
      }
    }, 200),
  },
})
