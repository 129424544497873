













import { defineComponent, nextTick } from '@nuxtjs/composition-api'
import useCatalogModal from '~/utils/compositions/useCatalogModal'

export default defineComponent({
	setup() {
		const { isOpen, close } = useCatalogModal()
		const clickMiddleware = (e: MouseEvent) => {
			return !(e.target as HTMLElement)?.className?.includes(
				'header-catalog-btn'
			)
		}
		const closeMenu = () => {
			setTimeout(() => {
				close()
			}, 0)
		}
		return { isOpen, closeMenu, clickMiddleware }
	},
})
