

















	import {computed, defineComponent} from '@nuxtjs/composition-api'
	import svgUser from '@/assets/icons/user.svg?inline'
	import {useAccessor} from "~/utils/compositions/useAccessor";
export default defineComponent({
	components: { svgUser },
	setup() {
		const accessor = useAccessor();
		const user = computed(() => accessor.user.userModel);
		const isAuth = computed(() => accessor.user.isAuth);

		return { user, isAuth }
	},
})
