













import { defineComponent, ref } from '@nuxtjs/composition-api'

import svgArrowDown from '@/assets/icons/arrow_down.svg?inline'
import svgBurger from '@/assets/icons/burger.svg?inline'
import useCatalogModal from '~/utils/compositions/useCatalogModal'

export default defineComponent({
  components: { svgArrowDown, svgBurger },
  setup() {
    const { isOpen } = useCatalogModal()
    return {
      isOpen,
    }
  },
})
