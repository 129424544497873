


























import { defineComponent } from '@nuxtjs/composition-api'
import { ModalName } from '~/types/modal.enum'
import useModal from '~/utils/compositions/useModal'

export default defineComponent({
	methods: {
		openConsult() {
			const { showByName } = useModal()
			showByName(ModalName.consultModal, {
				props: {
					formName: 'Consultation (header) ',
				},
			})
		},
	},
})
