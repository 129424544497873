



























import { defineComponent } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { MenuItemEntity } from '@/utils/models/menu-item'

export default defineComponent({
  props: {
    items: {
      type: Array as () => MenuItemEntity[],
      defualt: () => [],
    },
  },
  setup() {
    // const items = [
    //   {
    //     title: 'Помощь',
    //     items: [
    //       {
    //         name: 'Доставка',
    //       },
    //       {
    //         name: 'Оплата',
    //       },
    //       {
    //         name: 'Возврат и обмен',
    //       },
    //       {
    //         name: 'Бонусы',
    //       },
    //       {
    //         name: 'Контакты',
    //       },
    //     ],
    //   },
    //   {
    //     title: 'Магазин',
    //     items: [
    //       {
    //         name: 'Пункты самовывоза',
    //       },
    //       {
    //         name: 'Магазин на Академической',
    //       },
    //       {
    //         name: 'Возврат и обмен',
    //       },
    //       {
    //         name: 'Бонусы',
    //       },
    //       {
    //         name: 'Контакты',
    //       },
    //     ],
    //   },
    //   {
    //     title: 'Сообщество',
    //     items: [
    //       {
    //         name: 'Доставка',
    //       },
    //       {
    //         name: 'Рейтинг детских товаров',
    //       },
    //       {
    //         name: 'Возврат и обмен',
    //       },
    //       {
    //         name: 'Медицинские центры',
    //       },
    //       {
    //         name: 'Вопрос ветеринару',
    //       },
    //     ],
    //   },
    // ]
  },
  computed: {
    normItems(): any {
      return this.items?.map((item) => {
        // item.type
        const itemModel = plainToClass(MenuItemEntity, item)
        return itemModel
      })
    },
  },
})
