
























import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'
import svgArrowRight from '@/assets/icons/arrow_right_triangle.svg?inline'
import { MenuItemEntity } from '~/utils/models/menu-item'
import useCatalogModal from '~/utils/compositions/useCatalogModal'
export default defineComponent({
	components: { svgArrowRight },
	props: {
		item: {
			type: MenuItemEntity,
			default: () => ({}),
		},
	},
	computed: {
		link(): string {
			return this.item.getLink(this.localePath)
		},
		subCategories(): any[] {
			return this.item.children || []
		},
		title(): string {
			return this.item.name
		},
	},
	methods: {
		closeMenu() {
			const { close } = useCatalogModal()
			close()
		},
	},
})
