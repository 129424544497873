












































































import {
	computed,
	defineComponent,
	toRefs,
	useRouter,
} from '@nuxtjs/composition-api'
import svgFavourite from '@/assets/icons/star.svg?inline'
import svgArrowBurger from '@/assets/icons/burger.svg?inline'
import svgCheckmark from '@/assets/icons/checkmark_square.svg?inline'
import svgBrand from '@/assets/icons/brand.svg?inline'
import svgMarker from '@/assets/icons/marker.svg?inline'
import svgSale from '@/assets/icons/sale.svg?inline'
import svgCart from '@/assets/icons/cart.svg?inline'
import svgHistory from '@/assets/icons/history.svg?inline'
import svgPhone from '@/assets/icons/phone.svg?inline'
import svgCompare from '@/assets/icons/compare.svg?inline'
import svgGift from '@/assets/icons/gift.svg?inline'
import svgGlobus from '@/assets/icons/globus.svg?inline'
import svgArrowRight from '@/assets/icons/arrow_right.svg?inline'
import svgInfo from '@/assets/icons/info_round.svg?inline'
import useMobileMenu from '~/utils/compositions/useMobileMenu'
import useModal from '~/utils/compositions/useModal'
import { ModalName } from '~/types/modal.enum'
import { phones } from '~/types/constants'
interface IMenuItem {
	name: string
	icon: any
	type?: string
	cnt?: number
	link?: string
}
export default defineComponent({
	components: { svgGlobus, svgMarker, svgArrowRight, svgPhone },
	props: {
		value: String,
	},

	computed: {
		menuItems(): IMenuItem[] {
			return [
				{
					name: this.$t('catalog') as string,
					icon: svgArrowBurger,
					type: 'catalog',
					link: '#',
				},
				{
					type: 'link',

					name: this.$t('brands') as string,
					icon: svgBrand,
					link: this.localePath({ name: 'brands' }),
				},
				// {
				// 	name: this.$t('stocksMany') as string,
				// 	icon: svgSale,
				// 	type: 'link',
				// 	link: this.localePath({ name: 'stocks' }),
				// },
				{
					type: 'link',

					name: this.$t('cart') as string,
					icon: svgCart,
					cnt: this.$accessor.cart.totalCnt,
					link: this.localePath({ name: 'checkout' }),
				},
			]
		},
		actionItems(): IMenuItem[] {
			return [
				// {
				// 	name: 'Мои заказы',
				// 	icon: svgCheckmark,
				// 	cnt: 4,
				// },
				{
					type: 'link',
					name: this.$t('wishList') as string,
					icon: svgFavourite,
					cnt: this.$accessor.favourite.items.length,
					link: this.localePath({ name: 'wishlist' }),
				},
				// COMPARE
				// {
				// 	type: 'link',

				// 	name: 'Сравнение',
				// 	icon: svgCompare,
				// 	link: this.localePath({ name: 'compare' }),
				// },
				{
					type: 'link',
					cnt: this.$accessor.watched.items.length,
					name: this.$t('watched') as string,
					icon: svgHistory,
					link: this.localePath({ name: 'watched' }),
				},
				// {
				// 	name: 'Бонусы',
				// 	icon: svgGift,
				// },
			]
		},
		infoItems(): any[] {
			return [
				{
					type: 'information',
					name: this.$t('information'),
					icon: svgInfo,
					link: '#',
				},
				{
					type: 'link',
					name: this.$t('contacts'),
					icon: svgMarker,
					iconAttrs: {
						class: 'w-3.5'
					},
					link: this.localePath('/page/kontakty/'),
				},
			]
		},
		activeCity(): string {
			return this.$accessor.city.activeCity(
				this.$accessor.city.cities(this.$i18n)
			)?.name as string
		},
		phones(): any[] {
			return phones
		},
	},
	methods: {
		openCityModal() {
			const { showByName } = useModal()
			showByName(ModalName.cityModal)
		},
		onItemClick(item: IMenuItem) {
			const { close } = useMobileMenu()
			if (item.type === 'link') {
				this.$router.push(item.link as string)
				close()
				return
			}
			if (item.type === 'catalog') {
				this.$emit('active', 'catalog')
				this.$emit('next')
			}
			if (item.type === 'information') {
				this.$emit('active', 'information')
			}
			// if (item.type === 'brand') {
			//   return
			// }
		},
	},
})
