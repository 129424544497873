
















import { defineComponent, nextTick, ref } from '@nuxtjs/composition-api'

import useCatalogModal from '~/utils/compositions/useCatalogModal'

export default defineComponent({
  methods: {
    toggleCatalogModal() {
      const { isOpen, open, close } = useCatalogModal()

      if (!isOpen.value) {
        if (this.$route.name === this.localeRoute({ name: 'index' })?.name) {
          this.$scrollTo({
            el: document.body,
          })
          return
        }
        open()
      } else {
        close()
      }
    },
  },
})
