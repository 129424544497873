













import { defineComponent } from '@nuxtjs/composition-api'
import { throttle } from '@/utils/helpers/throttle'
export default defineComponent({
	setup() {
		return {}
	},
	data() {
		return {
			isFull: true,
			oldTop: 0,
			// checkScrollThrottle: throttle(this.checkScroll, 300),
		}
	},
	mounted() {
		this.checkScroll()

		window.addEventListener('scroll', this.checkScroll)
	},
	methods: {
		checkScroll() {
			const top = document.documentElement.scrollTop
			if (top - 20 <= 0) {
				this.isFull = true
				return
			}
			if (this.oldTop > top) {
				// top
				this.isFull = true
			} else {
				// bottom
				this.isFull = false
			}
			this.oldTop = top
		},
	},
})
