

































import { defineComponent } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'
import useMobileMenu from '~/utils/compositions/useMobileMenu'
import { CategoryEntity } from '~/utils/models/category.entity'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
	props: {
		items: {
			type: Array as () => MenuItemEntity[],
			default: () => [],
		},
	},
	computed: {
		normItems(): any[] {
			return this.items.map((item) => {
				const name = item.getName()
				const link = item.getLink(this.localePath)
				const categoryModel = plainToClass(CategoryEntity, item.value)
				const image = categoryModel.defaultImage
				const id = item.id
				const children = item.children
				return { name, link, image, id, children }
			})
		},
	},
	methods: {
		itemClick(item: any) {
			if (item.children && item.children.length) {
				this.$emit('next', item.id)
			} else {
				this.$router.push(item.link)
				this.$emit('close')
			}
		},
		goBack() {
			this.$emit('back')
		},
	},
})
