





























import { defineComponent } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'
import { CategoryEntity } from '~/utils/models/category.entity'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
	props: {
		items: {
			type: Array as () => MenuItemEntity[],
			default: () => [],
		},
		title: String,
	},
	computed: {
		normItems(): any[] {
			return this.items.map((item) => {
				const name = item.getName()
				const link = item.getLink(this.localePath)
				const categoryModel = plainToClass(CategoryEntity, item.value)
				const image = categoryModel.defaultImage
				return { name, link, image }
			})
		},
	},
	methods: {
		close() {
			this.$emit('close')
		},
		goBack() {
			this.$emit('back')
		},
	},
})
