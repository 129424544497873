














































































import { defineComponent } from '@nuxtjs/composition-api'
import svgPhone from '@/assets/icons/smartphone.svg?inline'
import svgEmail from '@/assets/icons/email.svg?inline'
import { phones } from '~/types/constants'
export default defineComponent({
	components: { svgPhone, svgEmail },
	data: () => ({ phones }),
	setup() {
		return {}
	},
})
