





















import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({})
//
