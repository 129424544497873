













import { defineComponent } from '@nuxtjs/composition-api'
import svgArrowRight from '@/assets/icons/arrow_right.svg?inline'
import useMobileMenu from '~/utils/compositions/useMobileMenu'
export default defineComponent({
	components: { svgArrowRight },
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
})
