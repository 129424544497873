






















import { defineComponent } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { useApiGetMenu } from '~/utils/api/menu'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
  name: 'MenuMobInfoList',
  data() {
    return {
      items: [] as MenuItemEntity[],
    }
  },
  async fetch() {
    await this.fetchItems()
  },
  computed: {
    normItems(): any[] {
      return this.items.map((item) => {
        const itemModel = plainToClass(MenuItemEntity, item)
        const name = itemModel.getName()
        const link = itemModel.getLink(this.localePath)
        return { name, link }
      })
    },
  },
  methods: {
    async fetchItems() {
      const data = false //this.$cache.data.get(`MenuMobInfoList_${this.$i18n.locale}`)
      let existCache = false
      if (data) {
        await Promise.resolve(data).then((res) => {
          if (res !== null && typeof res !== 'undefined') {
            this.items = res as any[]
            existCache = true
          }
        })
      }

      if (!existCache) {
        const { exec, result } = useApiGetMenu()
        await exec({ slug: 'information', locale: this.$i18n.locale })
        // this.$cache.data.set(`MenuMobInfoList_${this.$i18n.locale}`, result.value?.data?.menuItems as any[])
        this.items = result.value?.data?.menuItems as any[]
      }
    },
    close() {
      this.$emit('close')
    },

    goBack() {
      this.$emit('back')
    },
  },
  // serverCacheKey() {
  //   // Todo: can add day or month for cache by js
  //   return 'MenuMobInfoList'
  // },
})
