







































import { computed, defineComponent } from '@nuxtjs/composition-api'
import useCatalogModal from '~/utils/compositions/useCatalogModal'

export default defineComponent({
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		closeMenu() {
			const { close } = useCatalogModal()
			close()
		},
	},
})
