



































import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activeItemLink: String,
    popular: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    itemsThird(): number {
      if (this.items.length <= 3) return 1
      return Math.floor(this.items.length / 3)
    },
    col1(): any[] {
      return this.items.slice(0, this.itemsThird)
    },
    col2(): any[] {
      return this.items.slice(this.itemsThird, this.itemsThird * 2)
    },
    col3(): any[] {
      return this.items.slice(this.itemsThird * 2, this.items.length)
    },
  },
})
