
















import { defineComponent } from '@nuxtjs/composition-api'
import svgArrowRight from '@/assets/icons/arrow_right_triangle.svg?inline'
import useCatalogModal from '~/utils/compositions/useCatalogModal'

export default defineComponent({
	components: { svgArrowRight },
	props: {
		link: String,
	},
	methods: {
		closeMenu() {
			const { close } = useCatalogModal()
			close()
		},
	},
})
