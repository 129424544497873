


























import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

import svgHome from '@/assets/icons/home.svg?inline'
import { createFromBreadcrumbsEntityToJsonLd } from '~/utils/models/schemes/breadcrumbSchema/breadcrumbEntityToSchema'

export default defineComponent({
  components: { svgHome },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  head() {
    return {
      script: [{ type: 'application/ld+json', json: createFromBreadcrumbsEntityToJsonLd(this.items).next().value }],
    }
  },

  setup(props) {
    const { items } = toRefs(props)
    const currentItem = computed(() => items.value[items.value.length - 1] || {})
    const breadcrumbsItems = computed(() => items.value.slice(0, items.value.length - 1))
    return {
      breadcrumbsItems,
      currentItem,
    }
  },
})
