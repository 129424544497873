import { render, staticRenderFns } from "./CatalogModalItems.vue?vue&type=template&id=88a02046&"
import script from "./CatalogModalItems.vue?vue&type=script&lang=ts&"
export * from "./CatalogModalItems.vue?vue&type=script&lang=ts&"
import style0 from "./CatalogModalItems.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CatalogModalPopular: require('/var/www/html/components/CatalogModal/CatalogModalPopular.vue').default,CatalogModalCategoriesItem: require('/var/www/html/components/CatalogModal/CatalogModalCategoriesItem.vue').default,CatalogModalFooter: require('/var/www/html/components/CatalogModal/CatalogModalFooter.vue').default})
