








import { defineComponent } from '@nuxtjs/composition-api'
import svgMarker from '@/assets/icons/marker.svg?inline'
export default defineComponent({
  components: { svgMarker },
  setup() {
    return {}
  },
})
