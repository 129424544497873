//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import useModal from '@/utils/compositions/useModal'
import useStopScroll from '@/utils/compositions/useStopScroll'

export default defineComponent({
  setup(props, { emit }) {
    const isOpen = ref(false)
    const activeName = ref('')
    const cProps = ref({})
    const cListeners = ref({})
    const activeModal = ref({})
    const modalTransition = ref('modal-fade')
    const { api } = useModal()
    const { stop: blockScroll, reset: resetScroll } = useStopScroll()
    const open = () => {
      isOpen.value = true
      blockScroll()
    }
    const close = () => {
      isOpen.value = false
      cProps.value = {}
      cListeners.value = {}
      activeName.value = ''
      resetScroll()
    }
    // eslint-disable-next-line no-shadow
    api.on('open', ({ component, name, transition, props = {}, on = {} }) => {
      cProps.value = props
      cListeners.value = on
      activeModal.value = component
      activeName.value = name
      if (transition) {
        modalTransition.value = transition
      }
      open()
    })
    api.on('close', () => {
      close()
    })
    const overlayClick = () => {
      if (cProps.value._closeOnOverlay === false) {
        return
      }
      close()
    }
    const modalClass = computed(() => `modal-${activeName.value}`)
    return {
      modalClass,
      isOpen,
      open,
      close,
      cListeners,
      cProps,
      activeModal,
      modalTransition,
      overlayClick,
    }
  },
})
