

























import { defineComponent } from '@nuxtjs/composition-api'

import svgArrowRight from '@/assets/icons/arrow_right.svg?inline'
import svgBrand from '@/assets/icons/brand_mock.svg?inline'
import svgDog from '@/assets/icons/dog_mock.svg?inline'
import useCatalogModal from '~/utils/compositions/useCatalogModal'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
  props: {
    items: {
      type: Array as () => MenuItemEntity[],
      default: () => [],
    },
  },
  components: { svgArrowRight },
  methods: {
    selectItem(item: MenuItemEntity) {
      this.$emit('input', item.id)
    },
    deselectItem() {
      this.$emit('input', undefined)
    },
    goToLink(item: MenuItemEntity) {
      this.$router.push(item.getLink(this.localePath))
      const { close } = useCatalogModal()
      close()
    },
  },
})
