










import { defineComponent } from '@nuxtjs/composition-api'
import svgCross from '@/assets/icons/cross_thin.svg?inline'
import svgArrowLeft from '@/assets/icons/arrow_long_left.svg?inline'
export default defineComponent({
  components: { svgCross, svgArrowLeft },
  props: {
    title: String,
  },
  setup(props, { emit }) {
    const goBack = () => {
      emit('back')
    }
    const close = () => {
      emit('close')
    }
    return { goBack, close }
  },
})
