




























import { defineComponent } from '@nuxtjs/composition-api'

import { useApiGetMenu } from '~/utils/api/menu'
import useCookie from '~/utils/compositions/useCookie'

export default defineComponent({
  name: 'Footer',
  setup() {
    const today = new Date()
    const currentYear = today.getFullYear()
    // const cookies = useCookie()
    // let cooks = cookies.getAll()
    // console.log(cooks)
    return { currentYear }
  },
  data: () => ({
    menu: {} as any,
  }),
  async fetch() {
    await this.fetchMenu()
  },
  computed: {
    menuItems(): any[] {
      return this.menu.menuItems || []
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$fetch()
    },
  },
  methods: {
    async fetchMenu() {
      const data = false //this.$cache.data.get(`Footer_${this.$i18n.locale}`)
      let existCache = false
      if (data) {
        await Promise.resolve(data).then((res) => {
          if (res !== null && typeof res !== 'undefined') {
            this.menu = res
            existCache = true
          }
        })
      }

      if (!existCache) {
        const { exec, error, result } = useApiGetMenu({ loading: true })
        await exec({ locale: this.$i18n.locale, slug: 'footer' })
        // this.$cache.data.set(`Footer_${this.$i18n.locale}`, result.value?.data)

        if (error.value) {
          return
        }

        this.menu = result.value?.data
      }
    },
  },
  // serverCacheKey() {
  //   // Todo: can add day or month for cache by js
  //   return 'Footer'
  // },
})
