





















import { defineComponent, ref } from '@nuxtjs/composition-api'
import CatalogMenu from '@/mixins/CatalogMenu.vue'
export default defineComponent({
	mixins: [CatalogMenu],
})
