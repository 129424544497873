import { render, staticRenderFns } from "./MenuMobCategoriesImages.vue?vue&type=template&id=4532afa8&"
import script from "./MenuMobCategoriesImages.vue?vue&type=script&lang=ts&"
export * from "./MenuMobCategoriesImages.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuMobSubHeader: require('/var/www/html/components/MenuMob/MenuMobSubHeader.vue').default,CategoryImage: require('/var/www/html/components/Base/CategoryImage.vue').default})
