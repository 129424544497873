





























import { computed, defineComponent } from '@nuxtjs/composition-api'

import svgCart from '@/assets/icons/cart.svg?inline'
import { beautyPrice } from '~/utils/helpers/beauty-price'

export default defineComponent({
  components: { svgCart },
  data() {
    return {
      price: 0,
    }
  },
  computed: {
    currency() {
      return this.$accessor.currency.symbol
    },
    cartPrice() {
      const { totalAmount } = this.$accessor.cart
      return beautyPrice(totalAmount as number, true)
    },
    cartCnt(): number {
      return this.$accessor.cart.totalCnt
    },
    items(): any {
      return this.$accessor.cart.items
    },
    products(): any {
      return this.$accessor.cart.products
    },
  },
})
