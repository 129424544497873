
















import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { useApiGetMenu } from '~/utils/api/menu'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
  setup() {},
  data: () => ({
    menu: {} as any,
  }),
  async fetch() {
    await this.fetchItems()
  },
  computed: {
    menuItems(): MenuItemEntity[] {
      return this.menu.menuItems || []
    },

    normItems(): MenuItemEntity[] {
      return this.menuItems.map((item) => plainToClass(MenuItemEntity, item))
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$fetch()
    },
  },
  methods: {
    async fetchItems() {

      const data = false //this.$cache.data.get(`Header_${this.$i18n.locale}`)
      let existCache = false
      if (data) {
        await Promise.resolve(data).then((res) => {
          if (res !== null && typeof res !== 'undefined') {
            this.menu = res
            existCache = true
          }
        })
      }
      if (!existCache) {
        const { exec, result, error } = useApiGetMenu({ loading: true })
        await exec({ locale: this.$i18n.locale, slug: 'header' })
        if (error.value) return
        // this.$cache.data.set(`Header_${this.$i18n.locale}`, result.value?.data?.menuItems as any[])
        this.menu = result.value?.data
      }
    },
  },
})
