

























import { defineComponent, computed } from '@nuxtjs/composition-api'
import svgUser from '@/assets/icons/user.svg?inline'
import useModal from '~/utils/compositions/useModal'
import { ModalName } from '~/types/modal.enum'
import {useAccessor} from "~/utils/compositions/useAccessor";
export default defineComponent({
  components: { svgUser },
  setup() {
  	const accessor = useAccessor()
	const isAuth = computed(() => accessor.user.isAuth)
	const user = computed(() => accessor.user.userModel)
    const login = () => {
      const { showByName } = useModal()
      showByName(ModalName.login)
    }

    return { login, isAuth, user }
  },
})
