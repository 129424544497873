








import { defineComponent, ref } from '@nuxtjs/composition-api'
import useMobileMenu from '@/utils/compositions/useMobileMenu'
export default defineComponent({
  setup() {
    const { isOpen, toggle } = useMobileMenu()

    return { isOpen, toggle }
  },
})
