









import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    src: String,
  },
  setup() {
    return {}
  },
})
