





































import { defineComponent } from '@nuxtjs/composition-api'
// import svgCompare from '@/assets/icons/compare.svg?inline'
import svgWishList from '@/assets/icons/star.svg?inline'
export default defineComponent({
	components: { svgWishList },

	computed: {
		favouriteCnt() {
			return this.$accessor.favourite.items.length
		},
		items() {
			return this.$accessor.favourite.items
		},
		products() {
			return this.$accessor.favourite.products
		},
	},
})
