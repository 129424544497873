
















import { defineComponent } from '@nuxtjs/composition-api'
import { phones } from '~/types/constants'

export default defineComponent({
	setup() {
		return {
			phones,
		}
	},
})
