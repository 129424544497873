














import { defineComponent } from '@nuxtjs/composition-api'

import svgFacebook from '@/assets/icons/facebook.svg?inline'
import svgInstagram from '@/assets/icons/instagram.svg?inline'
import svgTelegram from '@/assets/icons/telegram.svg?inline'
import svgTikTok from '@/assets/icons/tiktok.svg?inline'

export default defineComponent({
  setup() {
    const socIcons = [
      {
        icon: svgInstagram,
        link: 'https://www.instagram.com/zoo.com.ua',
      },
      {
        icon: svgFacebook,
        link: 'https://www.facebook.com/zoo.com.ua',
      },
      {
        icon: svgTikTok,
        link: 'https://www.tiktok.com/@zoocomua',
      },
    ]
    return { socIcons }
  },
})
