import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=15a52f4c&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterInfoCol: require('/var/www/html/components/Footer/FooterInfoCol.vue').default,FooterMenu: require('/var/www/html/components/Footer/FooterMenu.vue').default,Footer: require('/var/www/html/components/Footer/Footer.vue').default})
