




















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
computed: {
    breadcrumbs() {
        return [
            {
                name: this.$t('page404.name')
            }
        ]
    }
}
})
