

































import { computed, defineComponent, ref, useRoute, useRouter, watch } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import useMobileMenu from '@/utils/compositions/useMobileMenu'
import { useApiGetMenu } from '~/utils/api/menu'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
  name: 'MenuMob',
  data: () => ({
    activeIdx: 0,
    prevActiveIdx: 0,
    items: [] as MenuItemEntity[],
    activeItemId: 0,
    activeType: 'catalog',
    catalogPath: ['main', 'categories', 'sub-categories'],
  }),
  async fetch() {
    await this.fetchMenu()
  },
  computed: {
    active(): string {
      return this.catalogPath[this.activeIdx]
    },
    activeItem(): MenuItemEntity {
      const activeMenuItem = this.normItems.find((item) => item.id === this.activeItemId)
      return activeMenuItem || ({} as MenuItemEntity)
    },
    normItems(): MenuItemEntity[] {
      return this.items.map((item) => plainToClass(MenuItemEntity, item))
    },
    transitionName(): string {
      return this.activeIdx < this.prevActiveIdx ? 't-switch' : 't-switch-back'
    },
    isOpen() {
      const { isOpen } = useMobileMenu()
      return isOpen.value
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$fetch()
    },
    activeIdx(value, prevValue: number) {
      this.prevActiveIdx = prevValue
    },
  },
  methods: {
    onInfoBack() {
      this.activeType = 'catalog'
    },
    setActiveType(type: string) {
      this.activeType = type
      this.prevActiveIdx = 0
      this.activeIdx = 0
    },
    // Todo: fix cache not working,
    async fetchMenu() {
      const data = false //this.$cache.data.get(`MobCatalog_${this.$i18n.locale}`)
      let existCache = false
      if (data) {
        await Promise.resolve(data).then((res) => {
          if (res !== null && typeof res !== 'undefined') {
            this.items = JSON.parse(res)
            existCache = true
          }
        })
      }

      if (!existCache) {
        const { exec, result, error } = useApiGetMenu()
        await exec({ slug: 'mob_catalog', locale: this.$i18n.locale })
        if (error.value) {
          return
        }
        //this.$cache.data.set(`MobCatalog_${this.$i18n.locale}`, JSON.stringify(result.value?.data?.menuItems))
        this.items = result.value?.data.menuItems as MenuItemEntity[]
      }
    },
    onBack() {
      if (this.activeIdx < 1) return
      this.activeIdx--
    },
    close() {
      const { close } = useMobileMenu()
      close()
    },
    onNext(itemId: number) {
      this.activeIdx++

      if (this.active === 'sub-categories') {
        this.activeItemId = itemId
      }
    },
    onClose() {
      const { close } = useMobileMenu()

      this.activeIdx = 0
      close()
    },
  },
  // serverCacheKey() {
  //   // Todo: can add day or month for cache by js
  //   return `MenuMob`
  // },
})
