import { render, staticRenderFns } from "./HeaderTopBar.vue?vue&type=template&id=3ec7f294&"
import script from "./HeaderTopBar.vue?vue&type=script&lang=ts&"
export * from "./HeaderTopBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderCity: require('/var/www/html/components/Header/HeaderCity.vue').default,HeaderDelivery: require('/var/www/html/components/Header/HeaderDelivery.vue').default})
