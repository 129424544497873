











import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    contentClass: [String, Array, Object],
    isOpen: Boolean,
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close')
    }
    return { close }
  },
})
