

















































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import svgArrowDown from '@/assets/icons/arrow_down.svg?inline'
import useModal from '~/utils/compositions/useModal'
import { ModalName } from '~/types/modal.enum'
export default defineComponent({
	components: { svgArrowDown },
	data: () => ({
		isOpen: false,
	}),
	computed: {
		cities(): any[] {
			return this.$accessor.city.cities(this.$i18n)
		},
		activeCity(): string {
			return this.$accessor.city.activeCity(this.cities)?.name || ''
		},
	},
	methods: {
		openCityModal() {
			const { showByName } = useModal()
			showByName(ModalName.cityModal)
		},
		selectCity(cityId: number) {
			this.$accessor.city.setActive(cityId)
			this.isOpen = false
		},
		toggle() {
			this.isOpen = !this.isOpen
		},
	},
})
