import { render, staticRenderFns } from "./FooterInfoCol.vue?vue&type=template&id=184377d4&"
import script from "./FooterInfoCol.vue?vue&type=script&lang=ts&"
export * from "./FooterInfoCol.vue?vue&type=script&lang=ts&"
import style0 from "./FooterInfoCol.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/var/www/html/components/Base/Logo.vue').default,FooterSocIcons: require('/var/www/html/components/Footer/FooterSocIcons.vue').default})
